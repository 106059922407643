import React, { useContext } from 'react'
import { rgba, lighten } from 'polished'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Media from 'src/components/Media'
import Image from 'src/components/Image'
import Video from 'src/components/Video'
import TextLockup from 'src/components/TextLockup'
import TextSection from 'src/components/TextSection'
import Button from 'src/components/Button'
import Splitter from 'src/components/Splitter'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Modal from 'src/components/Modal'
import { AppContext } from 'src/state/AppState'
import { mq, globals, typography, util, animations, colors } from 'src/styles'

const VideoSection = styled(Section)`
  ${ mq.largeAndBelow } {
    ${ globals.verticalSpacing('padding-bottom') }
  }
`

const ColumnWrapper = styled.div`
  h1, h2, h3 {
    max-width: 20em;
  }
  h4, h5 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 40em;
  }
`

const TextWrapper = styled.div`
`

const VideoModal = styled(Modal)`
  .close-button {
    background: rgba(0, 0, 0, .6);
    top: 10px;
    right: 10px;
    .icon {
      font-size: 24px;
      color: ${ colors.white };
    }
    &:hover {
      transform: none;
      background: ${ colors.mainColor };
    }
  }
`

const ModalContent = styled.div`
  width: calc(100vw - 40px);
  max-width: 1200px;
  video {
    border-radius: var(--card-border-radius);
  }
  ${ mq.largeAndUp } {
    width: 80vw;
  }
  video {
    display: block;
  }
`

const PlayButton = styled(Button)`
  .icon {
    font-size: 48px;
    z-index: 2;
    position: relative;
    transition: transform ${ animations.mediumSpeed } ease-in-out;
  }
  box-shadow: none;
  background: transparent;
  border-color: transparent;
  background: transparent;
  border-color: ${ colors.white };
  color: ${ colors.white };
  position: relative;
  display: block;
  &:after {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
    transition: border ${ animations.mediumSpeed } ease-in-out;
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    border: 2px solid ${ colors.white };
    z-index: 1;
    ${ mq.mediumAndBelow } {
      border-width: 23px;
      border-color: ${ colors.mainColor };
    }
  }
  &:hover {
    transform: none;
    background: transparent;
    border-color: ${ colors.mainColor };
    color: ${ colors.white };
    &:after {
      border-width: 23px;
      border-color: ${ colors.mainColor };
    }
  }
`

const PlayTriggerOverlay = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  transition: background ${ animations.mediumSpeed } ease-in-out;
  ${ util.responsiveStyles('padding', 50, 40, 30, 20) }
  color: ${ colors.white };
  background: ${ rgba(colors.textColor, 0.2) };
  p {
    margin: 0 0 0 1em;
    font-weight: ${ typography.bold };
    ${ mq.largeAndUp } {
      transition: transform ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
      opacity: 0;
      transform: translate3d(0, .5em, 0);
    }
  }
  &:hover {
    background: ${ rgba(colors.textColor, 0.5) };
    p {
      opacity: 1;
      transform: none;
    }
    ${ PlayButton } {
      border-color: ${ colors.mainColor };
      &:after {
        border-width: 23px;
        border-color: ${ colors.mainColor };
      }
      &:hover {
        &:after {
          border-color: ${ lighten(0.07, colors.mainColor) };
        }
      }
    }
  }
`

const VideoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`

const VideoSectionHeadline = styled.h3`
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  ${ typography.h1 }
  border-top: 1px solid var(--hr-color);
  padding: .5em 0 1em;
  margin: 0 15px;
  ${ ({ firstItem }) => !firstItem ? `
    margin-top: 1em;
  ` : '' }
`

const VideoThumbnail = styled.div`
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0 calc(30px / 2) 0;
  ${ util.responsiveStyles('padding-bottom', 70, 60, 50, 30) }
  ${ mq.mediumAndUp } {
    width: 50%;
  }
  ${ mq.largerAndUp } {
    width: 33.333%;
  }
  ${ mq.extraLargeAndUp } {
    width: 25%;
  }
  ${ PlayTriggerOverlay } {
    ${ util.responsiveStyles('padding', 20, 16, 16, 16) }
    &:hover {
      ${ PlayButton } {
        &:after {
          border-width: 20px;
        }
      }  
    }
    ${ PlayButton } {
      width: 42px;
      height: 42px;
      min-width: 42px;
      min-height: 42px;
      border: none;
      background: ${ rgba(0, 0, 0, 0.5) };
      &:after {
        border-width: 0;
      }
      &:hover {
        &:after {
          border-width: 20px;
        }
      }
      .icon {
        font-size: 30px;
      }
    }
  }
`

const VideoPage = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  featuredVideo,
  videos,
  isFirstSection,
}) => {
  const { toggleModal } = useContext(AppContext)

  // set responsive image sizes
  let sizes = '100vw'
  const imageSize = 100 / 28 * 13
  sizes = '(min-width: ' + mq.mediumBreakpoint + 'px) ' + imageSize + 'vw, 86vw'

  if (videos?.length < 1) {
    return false
  }

  return (
    <>
      <TextSection
        headline={<><span className='blue'>Welcome</span> to Ascend</>}
        headlineSize='h1'
        isFirstSection={isFirstSection}
        alignment='left'
        nextTheme='default'
      />
      <VideoSection
        className={className}
        setTheme='default'
        prevTheme='default'
        nextTheme='default'
      >
        <Grid
          small='m [1] m'
        >
          <Grid
            small='[1]'
            large='[6] [3]'
            colGap='30px'
            rowGap={['6vw', '4vw', '80px']}
            vAlign='top'
          >
            {featuredVideo?.coverMedia && (
              <ColumnWrapper>
                <ScrollEntrance>
                  <div style={{ position: 'relative' }}>
                    <Media
                      media={featuredVideo?.coverMedia}
                      loading={isFirstSection ? 'eager' : 'lazy'}
                      alt={'Watch: ' + featuredVideo?.title}
                      sizes={sizes}
                      css={css`position: relative; z-index: 1;`}
                    />
                    {featuredVideo.vimeoId && (
                      <PlayTriggerOverlay
                        onClick={() => toggleModal(featuredVideo?._key + '-featured-video-modal')}
                      >
                        <div css={css`display: flex; align-items: center;`}>
                          <PlayButton shape='circle' icon='play_arrow'/>
                          <p>Play video</p>
                        </div>
                      </PlayTriggerOverlay>
                    )}
                  </div>
                </ScrollEntrance>
              </ColumnWrapper>
            )}

            <ColumnWrapper>
              <Grid
                small='[1]'
                medium='[1]'
                large='[1]'
              >
                <TextWrapper>
                  <TextLockup
                    entranceDelay={1}
                    eyebrow={featuredVideo.eyebrow || 'Featured video'}
                    text={featuredVideo.description}
                    headline={featuredVideo.title}
                    headlineSize='h2'
                    textSize='body'
                    showHr={false}
                    actions={[
                      <Button
                        onClick={() => toggleModal(featuredVideo?._key + '-featured-video-modal')}
                        icon='play_arrow'
                      >Play video</Button>
                    ]}
                    theme={theme}
                    alignment='left'
                  />
                </TextWrapper>
              </Grid>
            </ColumnWrapper>
          </Grid>
        </Grid>
      </VideoSection>

      <Section
        className={className}
        prevTheme='default'
        nextTheme='lightGrey'
      >
        <Grid small='m [1] m'>
          <VideoGrid>
            {videos.map((item, index) => {
              if (item._type === 'videoHeadline') {
                return (
                  <VideoSectionHeadline
                    key={item._key}
                    firstItem={index === 0}
                  >{item.headline}</VideoSectionHeadline>
                )
              }
              if (item._type === 'videoItem') {
                return (
                  <VideoThumbnail
                    key={item._key}
                    onClick={() => toggleModal(item._key + '-video-modal')}
                  >
                    <div style={{ position: 'relative' }}>
                      <Image image={item.asset.gatsbyImageData}/>
                      <PlayTriggerOverlay
                        onClick={() => toggleModal(featuredVideo?._key + '-featured-video-modal')}
                      >
                        <div css={css`display: flex; align-items: center;`}>
                          <PlayButton shape='circle' icon='play_arrow'/>
                          <p>Play video</p>
                        </div>
                      </PlayTriggerOverlay>
                    </div>
                    <p css={css`${ typography.h4 } margin: .5em 0 0 0`}>{item.title}</p>
                  </VideoThumbnail>
                )
              }
              return false
            })}
          </VideoGrid>
        </Grid>
      </Section>

      <Splitter
        prevTheme='default'
        theme='lightGrey'
        nextTheme={false}
      />

      {featuredVideo.vimeoId && (
        <VideoModal
          id={featuredVideo?._key + '-featured-video-modal'}
          key={featuredVideo?._key + '-featured-video-modal'}
        >
          <ModalContent>
            <Video
              src={'https://vimeo.com/' + featuredVideo.vimeoId}
              id='modalVideo'
              controls={true}
              muted={false}
              loop={false}
              onEnded={() => toggleModal(false)}
              allowFullscreen={true}
            />
          </ModalContent>
        </VideoModal>
      )}

      {videos.map((video, index) => {
        return (
          <VideoModal
            id={video?._key + '-video-modal'}
            key={video?._key + '-video-modal'}
          >
            <ModalContent>
              <Video
                src={'https://vimeo.com/' + video.vimeoId}
                id='modalVideo'
                controls={true}
                muted={false}
                loop={false}
                onEnded={() => toggleModal(false)}
                allowFullscreen={true}
              />
            </ModalContent>
          </VideoModal>
        )
      })}
    </>
  )
}

export default VideoPage
